
<template>
<div>
  <div v-if="!isLoading" class="row gy-2">
    <div v-if="hospital" class="col-12">
      <h5 class="mb-3">Hospital Details</h5>
      <div class="row g-4">
        <div class="col-md-8">
          <div class="card overflow-hidden">
            <div class="card-body">
              <div class="row gx-2 gx-lg-4">
                <div class="col-10 col-sm-8">
                  <div class="d-flex align-items-center justify-content-start mb-3">
                    <div class="me-2">
                      <div class="avatar img-thumbnail flex-shrink-0">
                        <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                          {{hospital.name.charAt(0).toUpperCase()}}
                        </div>
                      </div>
                    </div>
                    <div class="">
                      <h4 class="lh-base mb-0">{{hospital.name}}</h4>
                    </div>
                  </div>
                </div>
                <div class="col-2 col-sm-4 text-end">
                  <!-- <div class="dropdown dropstart no-arrow">
                    <a class="dropdown-toggle btn btn-sm btn-light" href="#"
                      role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <i class="ri-more-2-fill"></i>
                    </a>
                    <ul class="dropdown-menu">
                      <li>
                        <button @click.prevent="deleteItem()"
                          class="dropdown-item" type="buttom"> Delete hospital</button>
                      </li>
                    </ul>
                  </div> -->
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-12">
                      <div class=""><strong> Hospital Code:</strong> <span>{{hospital.hp_code}}</span></div>
                      <div class="mt-2"><strong> Ward:</strong> <span v-if="hospital.ward">{{hospital.ward.name }}</span></div>
                      <div class="mt-2" v-if="hospital.description">
                        <label class="form-label fw-bold">Description</label>
                        <div v-html="preText(hospital.description)"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-sticky" style="top: 90px;">
            <div class="card shadow-sm">
              <div class="card-body">
                <h6>Local Government</h6>
                <p v-if="hospital.lga" class="mb-0">{{ hospital.lga.name}}</p>
              </div>
            </div>
            <div class="card shadow-sm">
              <div class="card-body">
                <h6>Senatorial District</h6>
                <p v-if="hospital.lga" class="mb-0">{{ hospital.lga.senatorial_district.name}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "hospital-show",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      hospital: null
    }
  },
  watch: {
    '$route.params.hospitalId': function (id){
      if(id) this.fetchItem()
    },
  },
  methods: {
    updateItem(item){
      Object.assign(this.hospital, item)
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/hospitals/${this.$route.params.hospitalId}/show`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.hospital = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    initializeAll(){
    }
  },
  created(){
    this.fetchItem()
  },
}

</script>

